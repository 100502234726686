import React from "react";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/modal';
import axios from "axios";


const Premium = () => {

    let user = JSON.parse(localStorage.getItem('data'));


    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pix, setPix] = useState([]);
    const [quantidade, setQuantidade] = useState();
    const [pagamentoPix, setPagamentoPix] = useState(false);
    const [cartao, setCartao] = useState(false);
    const [carregarpix, setCarregarPix] = useState(false);
    const [erropix, setErroPix] = useState(false);




    


    const [copyPix, setcopyPix] = useState(false);






    const comprar = (e) => {

    const id = e.currentTarget.id;
        setShow(true)
        setQuantidade(id)
        setPagamentoPix(false)
        setCarregarPix(false)
        setCartao(false)

    }

    const pagarPix = () =>{

        setCarregarPix(true)

        let dados = new FormData();
        dados.append("user", user.id);
        dados.append("nome", user.nome);
        dados.append("quantidade", quantidade);
        dados.append("email", user.email);


        axios.post('https://api2.design1.com.br/pagamentoPix.php', dados).then(function(response) {
            if(response.data.pagamento == 'erro'){
               setErroPix(true);
              setCarregarPix(false)

            }else{
            setPix(response.data);
            setPagamentoPix(true);
           

        //VERIFICAR A CADA 2 SEGUNDOS SE FOI PAGO
      
            setInterval(() => {
                axios.get(`https://api2.design1.com.br/checandoPix.php?id_compra=${response.data.id_compra}`).then(function(resultado) {      
                
                //SE RESULTADO FOR TRUE (APPROVED) redireciona para pagina de download
                if(resultado.data.resultado){
                    alert('Pagamento efetuado com sucesso!')
                    window.location.replace('/painel');  

                }
            });
            }, 3000);
            }
       
            });
    }

    const copiarPix = async() =>{
        const pixcode = document.getElementById('pixcode').select();
        document.execCommand("copy");
        setcopyPix(true);
    }

    const pagarCartao = () =>{
        
        setCartao(true)

        let dados = new FormData();
        dados.append("user", user.id);
        dados.append("quantidade", quantidade);
        dados.append("email", user.email);


        axios.post('https://api2.design1.com.br/pagamentoCartao.php', dados).then(function(response) {
            window.location.replace(response.data.pagamento);     

    });
    }

    const facaLogin = () =>{
        alert('Para ser premium você precisa fazer login!');
        window.location.replace('/login?rt=premium');  

    }

   
    if(loading){ 
        return(
        <div className="container p-5"><i className="fa-solid fa-circle-notch fa-spin"></i> Carregando...</div>)
        }else{
        
    return(
    <div className="container p-5">

                <div className="text-center mb-4"> <h5>Torne-se Premium</h5>
                <span className="small"><strong>Sem mensalidade!</strong> Seja um usuário premium e acelere sua produtividade.</span>
                </div> 


                <div className="row d-flex justify-content-center mb-4">
                    <div className="col-lg-9">
                         <div className="info-mensagem"><strong>Baixe quando quiser. Seus downloads nunca expiram!</strong></div>              
                   </div>
                </div>

        <div className="row d-flex justify-content-center">
           
            <div className="col-lg-3 mb-5">
                <div className="planos">
                    <div className="titulo-plano bg-padrao">
                    <h3 className="m-0 p-0">10 DOWNLOADS</h3>
                    </div>

                    <div className="info-plano">
                    <h2 className="text-center"><strong><span className="moeda">R$</span>12,00</strong></h2>
                    <div className="text-center"><span className="valor-arquivo">R$ 1,20 por arquivo</span></div>

                    <hr/>
                    <div className="small">
                    <i className="fa-solid fa-circle-check"></i> Liberação imediata<br/>
                    <i className="fa-solid fa-circle-check"></i> Qualquer arquivo do site<br/>
                    <i className="fa-solid fa-circle-check"></i> <strong>Arquivos editáveis no Photoshop</strong><br/>
                    <i className="fa-solid fa-circle-check"></i> <strong>Arquivos editáveis no Canva</strong><br/>
                    <i className="fa-solid fa-circle-check"></i> Atualizações diárias<br/>
                    </div>
                </div>
                <div className="p-3 text-center">
                   {user ? <a href="javascript:void(0)" id="10" onClick={comprar} className="btn botao-comprar"><strong>COMPRAR AGORA</strong></a>:
                    <a href="javascript:void(0)" onClick={facaLogin} className="btn botao-comprar"><strong>COMPRAR AGORA</strong></a>
                    }
                    </div>
                </div>
            </div>



            <div className="col-lg-3 mb-5">
                <div className="planos">
                    <div className="titulo-plano bg-padrao">
                    <h3 className="m-0 p-0">50 DOWNLOADS</h3>
                    

                    </div>

                    <div className="info-plano">
                    <h2 className="text-center"><strong><span className="moeda">R$</span>45,00</strong></h2>
                    <div className="text-center"><span className="valor-arquivo">R$ 0,90 por arquivo</span></div>
                    <hr/>
                    <div className="small">
                    <i className="fa-solid fa-circle-check"></i> Liberação imediata<br/>
                    <i className="fa-solid fa-circle-check"></i> Qualquer arquivo do site<br/>
                    <i className="fa-solid fa-circle-check"></i> <strong>Arquivos editáveis no Photoshop</strong><br/>
                    <i className="fa-solid fa-circle-check"></i> <strong>Arquivos editáveis no Canva</strong><br/>
                    <i className="fa-solid fa-circle-check"></i> Atualizações diárias<br/>
                    </div>
                </div>
                <div className="p-3 text-center">
                {user ? <a href="javascript:void(0)" id="50" onClick={comprar} className="btn botao-comprar"><strong>COMPRAR AGORA</strong></a>:
                    <a href="javascript:void(0)" onClick={facaLogin} className="btn botao-comprar"><strong>COMPRAR AGORA</strong></a>
                    }
                </div>
                </div>
            </div>


            <div className="col-lg-3 mb-5">
                <div className="planos">
                    <div className="titulo-plano bg-padrao">
                    <h3 className="m-0 p-0">100 DOWNLOADS</h3>

                    </div>

                    <div className="info-plano">
                    <h2 className="text-center"><strong><span className="moeda">R$</span>80,00</strong></h2>
                    <div className="text-center"><span className="valor-arquivo bg-success text-white border-0">R$ 0,80 por arquivo</span></div>
                    <hr/>
                    <div className="small">
                    <i className="fa-solid fa-circle-check"></i> Liberação imediata<br/>
                    <i className="fa-solid fa-circle-check"></i> Qualquer arquivo do site<br/>
                    <i className="fa-solid fa-circle-check"></i> <strong>Arquivos editáveis no Photoshop</strong><br/>
                    <i className="fa-solid fa-circle-check"></i> <strong>Arquivos editáveis no Canva</strong><br/>
                    <i className="fa-solid fa-circle-check"></i> Atualizações diárias<br/>
                    </div>
                </div>
                <div className="p-3 text-center">
                {user ? <a href="javascript:void(0)" id="100" onClick={comprar} className="btn botao-comprar"><strong>COMPRAR AGORA</strong></a>:
                    <a href="javascript:void(0)" onClick={facaLogin} className="btn botao-comprar"><strong>COMPRAR AGORA</strong></a>
                    }
                </div>
                </div>
            </div>



            <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton>
              <strong>Pagamento</strong>
            </Modal.Header>
            <Modal.Body className="text-center p-5">

             {!pagamentoPix && <div>
                    
                    
                    {!carregarpix ? <div className="mb-3"><a className="btn botao-pd" id={quantidade} onClick={pagarPix}><i className="fa-brands fa-pix"></i> Pagar com PIX</a></div>:
                    <div className="mb-3"><a className="btn btn-light"><i className="fa-solid fa-circle-notch fa-spin"></i> Carregando...</a></div>}
              
                    {erropix && <div className="mb-3"><span className="text-danger small"> Erro ao gerar chave pix, tente novamente!</span></div>}
    
        

                    {!cartao ? <div><a className="btn botao-pd" id={quantidade} onClick={pagarCartao}><img src="imagens/mercadopago.png" width="30px"/> Pagar com Mercado Pago</a></div>:
                    <div><a className="btn btn-light"><i className="fa-solid fa-circle-notch fa-spin"></i> Carregando...</a></div>}
                   
                    </div>

              }


                    {pagamentoPix && 
                
                <div className="text-center">

                        
                         <div className="bg-light p-3 rounded border">
                            <span className="small "><strong>Copie o código PIX e cole no aplicativo de seu banco</strong></span><br />
                            <input type="text" id="pixcode" readonly className="form-control mb-3" value={pix.pagamento.transaction_data.qr_code} />
                            {!copyPix && <button className="btn botao-pd px-5" onClick={copiarPix}><i className="fa-regular fa-copy"></i> Copiar</button>}
                            {copyPix && <button className="btn botao-pd px-5" onClick={copiarPix}><i className="fa-solid fa-circle-check"></i> Copiado</button>}

                        </div>
                      
                         <div className="mt-3">
                         <span className="small "><strong>Ou escaneie o QR Code</strong></span><br />

                            <img src={`data:image/jpeg;base64,${pix.pagamento.transaction_data.qr_code_base64}`} width="200px" />
                        
                        </div>

                        <span className="small"><i className="fa-solid fa-rotate fa-spin"></i> Detectando pagamento...</span>

                        <hr/>
                         
                        <div className="mt-2"><img src="../imagens/pagamento_seguro.jpg" width="155px" /></div>

                        </div>}


            </Modal.Body>

            </Modal>
            



        </div>
    </div>
)
} 

}

export default Premium