import React from "react";

const facaLogin = () =>{

    return(

    <div className="container p-5 parceiro text-center">
        <div className="p-4 bg-white rounded ">
        <p>Se você é apaixonado por design e deseja ganhar dinheiro com suas criações, convidamos você a se juntar a nós. Envie seus designs e receba uma comissão de R$ 0,50 sobre cada venda.</p>
        <p>Além de ganhar dinheiro com suas criações, você também terá a oportunidade de expandir sua visibilidade e alcançar um público maior.
</p>

<a href="/programa-parceria" ><i className="fa-solid fa-plus"></i> Saiba mais</a>
      </div>


    <div className="mt-4">
    <a href="/login">Entrar agora </a>  ou  <a href="/cadastrar">Cadastrar</a>
    </div>

        </div>

    )

}
export default facaLogin;