const Politica = () =>{

    return(
        <div className="container p-5">
            <h1 className="h5"> Política de Privacidade</h1>
       

<p>Esta Política de Privacidade descreve como o Design1 coleta, usa e compartilha informações pessoais dos usuários. Ao usar o site, você concorda com a coleta e uso de suas informações pessoais conforme descrito nesta política.</p>

<p><strong>1. Informações Coletadas</strong><br/>
Podemos coletar as seguintes informações pessoais dos usuários:<br/>
- Nome completo<br/>
- Endereço de e-mail<br/>
- Informações de pagamento<br/>
- Informações de localização<br/>
- Informações sobre o dispositivo usado para acessar o site</p>

<p><strong>2. Uso de Informações</strong><br/>
Usamos as informações coletadas para os seguintes fins:<br/>
- Permitir que os usuários façam o download de arquivos<br/>
- Processar pagamentos<br/>
- Enviar atualizações sobre o site e os serviços oferecidos<br/>
- Personalizar a experiência do usuário no site<br/>
- Melhorar o desempenho e funcionalidade do site<br/>
- Cumprir obrigações legais</p>

<p><strong>3. Compartilhamento de Informações</strong><br/>
Não compartilhamos as informações pessoais dos usuários com terceiros, exceto nos seguintes casos:<br/>
- Quando o usuário solicita o compartilhamento de suas informações com um terceiro<br/>
- Para cumprir obrigações legais ou regulatórias<br/>
- Para investigar e prevenir fraudes ou atividades ilegais<br/>
</p>

<p><strong>4. Armazenamento de Informações</strong><br/>
As informações pessoais dos usuários são armazenadas em servidores seguros e protegidas por medidas de segurança apropriadas. As informações são mantidas pelo tempo necessário para cumprir os fins para os quais foram coletadas.
</p>

<p><strong>5. Cookies</strong><br/>
Usamos cookies para personalizar a experiência do usuário no site e para fins analíticos. Os usuários podem optar por desativar os cookies em seus navegadores, mas isso pode afetar a funcionalidade do site.
</p>

<p>
<strong>6. Alterações na Política de Privacidade</strong><br/>
Reservamo-nos o direito de alterar esta Política de Privacidade a qualquer momento, sem aviso prévio. As alterações entrarão em vigor imediatamente após a publicação no site.
</p>


<p><strong>7. Contato</strong><br/>
Se tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco por meio do formulário de contato no site.
</p>

</div>
    )

}
export default Politica