import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom'

const RecuperarSenha = () =>{

    const [novasenha, setNovaSenha] = useState();
    const [erro, setErro] = useState();
    const [check, setCheck] = useState();
    const [user, setUser] = useState();




    const pegaurl = useParams(); // pegar slug

    useEffect(() => {
            axios.get(`https://api2.design1.com.br/login/checkRecuperacao.php?chave=${pegaurl.chave}`).then(function(response) {
                //console.log(response.data);
                setCheck(response.data.status);
                setUser(response.data.user);

            });
        }, []);


    const checksenha = (e) =>{

        if(e.target.value != novasenha){
            setErro(true);
        }else{
            setErro(false);

        }
       
    }

    const novaSenha = (e) =>{
        e.preventDefault();
        const chave = pegaurl.chave;
    axios.post(`https://api2.design1.com.br/login/updateSenha.php`, {user, chave, novasenha})
      .then(function (response) {
        if(response.data){
            alert('Senha alterada com sucesso')
            window.location.replace('/login')
            console.log(response.data)
        }
    })
    }


    return(
        <div className="container p-5">
        <div className="row d-flex justify-content-center">
            <div className="col-lg-4 login p-4 bg-white rounded text-center">
            <h5 className="mb-3">Nova senha</h5>

                {check ? <form onSubmit={novaSenha}>
                    <input type="password" minlength="6" name="email" className="form-login mb-3" placeholder="Nova senha" onChange={e => setNovaSenha(e.target.value)}/>
                    <input type="password" name="senha" className="form-login mb-3" placeholder="Repetir a nova senha" onChange={checksenha}/>
                    {!erro &&  <button type="submit" className="botao-login botao-pd">Salvar</button>}
                    </form> : <div className=" mt-3 alert alert-danger" role="alert">Recuperação expirada!</div>}

                {erro && <div className=" mt-3 alert alert-danger" role="alert">As senhas não conferem</div>}
             
                
            </div>
        </div>
        </div>
    )
}
export default RecuperarSenha