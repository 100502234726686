import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import Buscador from '../componentes/buscador';
import Masonry from 'react-smart-masonry';
import { useSearchParams } from "react-router-dom";


const Busca = () =>{


    const [loading, setLoading] = useState(false);
    const [listar, setDesigns] = useState([]);
    const [totalpaginas, setTotalPaginas] = useState();

     //GET QUERYS
     const [searchParams] = useSearchParams();
     var q = searchParams.get('q');
     var getpagina = searchParams.get('pagina');
     var formato = searchParams.get('formato');
     var valor = searchParams.get('valor');
     var categoria = searchParams.get('categoria');

 
     if(!getpagina){
        var pagina = 1
     }else{
        var pagina = getpagina
     }
  


    useEffect(() => {
        setLoading(true);
            axios.get(`https://api2.design1.com.br/buscar.php?q=${q}${formato ? `&formato=${formato}`:``}${pagina ? `&pagina=${pagina}`:``}${valor ? `&valor=${valor}`:``}${categoria ? `&categoria=${categoria}`:``} `).then(function(response) {
                setDesigns(response.data.designs);
                setTotalPaginas(response.data.totalpages);
        setLoading(false);

        });
    }, []);

    

    return(

        <><div className="container-fluid bg-pd p-3">
            <div className="container">
                <Buscador titulo={q}/>
            </div>
        </div>


        <div className="container p-4 small filtro d-flex justify-content-center align-items-center">
            <span className="text-secondary d-block pe-2"><strong>FILTRAR</strong> </span>   
              <a href={`?q=${q}&formato=photoshop${pagina > 1 ? `&pagina=${pagina}`:``}${valor ? `&valor=${valor}`:``}${categoria ? `&categoria=${categoria}`:``} `} className="btn btn-outline-secondary btn-sm me-2">{formato === 'photoshop' ? <i className="fa-solid fa-square-check"></i>: ''} PSD</a>
              <a href={`?q=${q}&formato=canva${pagina > 1 ? `&pagina=${pagina}`:``}${valor ? `&valor=${valor}`:``}${categoria ? `&categoria=${categoria}`:``} `} className="btn btn-outline-secondary btn-sm me-2">{formato === 'canva' ? <i className="fa-solid fa-square-check"></i>: ''} CANVA</a>
              {!valor ? <a href={`?q=${q}&valor=gratis${formato ? `&formato=${formato}`:``}${pagina > 1 ? `&pagina=${pagina}`:``}${categoria ? `&categoria=${categoria}`:``} `} className="btn btn-outline-secondary btn-sm me-2">{valor === 'gratis' ? <i className="fa-solid fa-square-check"></i>: ''} GRÁTIS</a>:
              <a href={`?q=${q}${formato ? `&formato=${formato}`:``}${categoria ? `&categoria=${categoria}`:``}`} className="btn btn-outline-secondary btn-sm me-2">{valor === 'gratis' ? <i className="fa-solid fa-square-check"></i>: ''} GRÁTIS</a>}
        </div>


        <hr className="m-0"/>

        <div className="container designs p-4" id="designs">

            
     

      <Masonry
            breakpoints={{ mobile: 0, tablet: 600, desktop: 1200 }}
            columns={{ mobile: 2, tablet: 3, desktop: 5 }}
            gap={{ mobile: 20, tablet: 20, desktop: 20 }}
            >
        {!loading && listar.map(dados => (
                <a href={`https://design1.com.br/d/${dados.slug}`}> 
                <div className="box-design rounded">
                    <div className="info d-flex justify-content-end  align-items-end">
                        <div>
                      
                        {dados.valor == 0 && <span className="valor bg-warning text-dark  border-0"><strong>GRÁTIS</strong></span>}
                        {dados.formato == 'canva' && <img src="https://design1.com.br/imagens/canva_white.png" className="ps-2" width="50px"/>}
                        {dados.formato == 'psd' && <img src="https://design1.com.br/imagens/photoshop_white.png" className="ps-2" width="27px"/>}

                        </div>
                    </div>
                    <img src={`//design1.com.br/imagens/t/${dados.imagem}`} alt={dados.titulo} className="img-fluid" />
                </div></a>
                ))}
                {loading && <>Carregando...</>}
    </Masonry>

                 <div className="m-3 text-center">
                    {pagina == 1 ? <a className="btn text-secondary btn-btn btn-outline-secondary" >Anterior</a>
                        :  <a href={`?q=${q}&pagina=${pagina-1}${formato ? `&formato=${formato}` : ``}${valor ? `&valor=${valor}` : ``}${categoria ? `&categoria=${categoria}`:``} `} className="btn botao-pd">Anterior</a>
                    }


                    <button className="btn btn-btn btn-outline-secondary me-2 ms-2">{pagina}</button>


                    {pagina < totalpaginas ? <a href={`?q=${q}&pagina=${pagina+++1}${formato ? `&formato=${formato}` : ``}${valor ? `&valor=${valor}` : ``}${categoria ? `&categoria=${categoria}`:``} `} className="btn botao-pd">Próximo</a> :
                        <a className="btn text-secondary btn-btn btn-outline-secondary">Próximo</a>}
      
                </div>

            </div></>

    )
}
export default Busca;