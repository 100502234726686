import { useEffect, useState } from "react";
import axios from "axios";


const Dmca = () =>{

    const [inputs, setInputs] = useState([]);
    const [result, setResult] = useState();



    //PEGAR VALUES DOS INPUTS
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const Submit = (e) =>{
        e.preventDefault();

        let dados = new FormData();
        dados.append("nome", inputs.nome);
        dados.append("email", inputs.email);
        dados.append("url", inputs.url);
        dados.append("descricao", inputs.descricao);


        axios.post(`https://design1.com.br/mail/dmca.php`, dados).then(function(response) {
            setResult(response.data);
        
    });

    }

return(
    <div className="container p-5">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-4 login p-4 bg-white rounded text-center">
                <h5 className="mb-3">Denúncia de direito autoral</h5>

                <p>Se encontrar qualquer arquivo ou elemento que infringe qualquer direito autoral que você possui, preencha o formulário abaixo que nossa equipe irá analisar o mais breve possível.
</p>

                    <form onSubmit={Submit}>

                        <input type="text" name="nome" className="form-login mb-3" placeholder="Seu nome" required onChange={handleChange} />
                     
                        <div className="mb-3">
                        <input type="email" name="email" className="form-login" placeholder="E-mail" required  onChange={handleChange}/>
                        </div>

                        <div className="mb-3">
                        <input type="text" name="url" className="form-login" placeholder="Url do arquivo" required  onChange={handleChange}/>
                        </div>

                        <div className="mb-3">
                        <textarea name="descricao" className="form-login" placeholder="Descrição" required  onChange={handleChange}></textarea>
                        </div>
                        

                        <button type="submit" className="botao-login botao-pd">Enviar</button>
                        {result && <div className="alert alert-success mt-3" role="alert"> Solicitação enviada com sucesso. Entraremos em contato.</div>}
        
                    
                    </form>
                </div>
            </div>
        </div>
)
}
export default Dmca