const Programa = () =>{
    return(
        <div className="container p-5">
            
            <h1 className="h5">Programa de Parceria - Oportunidade para Designers</h1>
            
            <p>Nossa plataforma oferece um programa de parceria exclusivo, permitindo que você venda seus designs prontos no Canva e Photoshop e ganhe uma comissão de R$ 0,50 em cada venda.</p>
            
            <h2 className="h5">Como funciona:</h2>
            
            <ol>
            <li>Envie seus designs: Como parte do programa de parceria, você poderá enviar seus arquivos de design exclusivos para nossa plataforma. Esses designs devem ser criados por você e não podem infringir os direitos autorais de outros profissionais. Queremos garantir que todos os designs sejam originais e autênticos.</li>
            <li>Venda seus designs: Após a aprovação dos seus designs pela nossa equipe, eles serão disponibilizados para compra na plataforma. Cada vez que um design seu for vendido, você receberá R$ 0,50.</li>
            <li>Saque seus ganhos: Para que você possa aproveitar seus ganhos, oferecemos a opção de saque duas vezes ao mês. Para solicitar um saque, é necessário acumular um valor mínimo de 100 reais em comissões. Dessa forma, garantimos que você possa receber seus ganhos regularmente e de forma conveniente.</li>
            </ol>
            
            <h2 className="h5">Benefícios do programa de parceria:</h2>
            
            <ul>
                <li>Ganhos justos: Reconhecemos o valor do seu trabalho como designer e garantimos que você seja recompensado de forma justa.</li>
                <li>Flexibilidade de envio: Você pode enviar quantos designs desejar, desde que sejam exclusivos e atendam às diretrizes de direitos autorais. Quanto mais designs você enviar, mais oportunidades terá de gerar vendas e aumentar seus ganhos.</li>
                <li>Oportunidade de crescimento: Nossa plataforma possui uma base de usuários em constante crescimento, o que significa que seus designs têm a chance de alcançar um público amplo e diversificado. Essa exposição pode abrir portas para novas oportunidades e aumentar sua visibilidade como profissional.</li>
                <li>Suporte e orientação: Nossa equipe estará sempre disponível para ajudá-lo no processo de envio de designs, garantindo que você entenda todas as diretrizes e requisitos necessários. Queremos que você tenha uma experiência positiva e produtiva em nossa plataforma.</li>
            </ul>
            
            <p>Aproveite esta oportunidade única para transformar seu talento em uma fonte de renda adicional. Junte-se ao nosso programa de parceria hoje mesmo, envie seus designs exclusivos e comece a ganhar dinheiro com o Canva e o Photoshop!</p><p>Lembre-se de que o sucesso do programa de parceria depende da qualidade, originalidade e conformidade legal dos seus designs. Certifique-se de criar conteúdo autêntico e evite qualquer violação de direitos autorais. Estamos ansiosos para receber seus designs e trabalhar em conjunto para impulsionar seu sucesso como profissional designer. Boas vendas!</p>
</div>   
    )
}
export default Programa