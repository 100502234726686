import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Masonry from 'react-smart-masonry';




const ListarDesigns = () =>{




    const [listar, setDesigns] = useState([]);
    const [loading, setLoading] = useState(false);
    
    


    //LISTAR DESIGNS RECENTES


    const listaDesigns = () => {
  
         axios.get(`https://api2.design1.com.br/listarDesigns.php`).then(function(response) {
        //console.log(response.data);
        setLoading(false);
        setDesigns(response.data.designs);
        
    });
}


useEffect(() => {
        setLoading(true);
        listaDesigns();
    }, []);


    return(

        <>
       
    
       <div className="container p-4 small filtro d-flex justify-content-center align-items-center">
            <span className="text-secondary d-block pe-2"><strong>FILTRAR</strong> </span>   
              <a href="/buscar?q=&formato=photoshop" className="btn btn-outline-secondary btn-sm me-2">PSD</a>
              <a href="/buscar?q=&formato=canva" className="btn btn-outline-secondary btn-sm me-2">CANVA</a>
              <a href="/buscar?q=&valor=gratis" className="btn btn-outline-secondary btn-sm me-2">GRÁTIS</a>
        </div>

        <hr className="m-0"/>

        
        
        <div className="container designs p-4" id="designs">

            

                <Masonry
                    breakpoints={{ mobile: 0, tablet: 600, desktop: 1200 }}
                    columns={{ mobile: 2, tablet: 3, desktop: 5 }}
                    gap={{ mobile: 20, tablet: 20, desktop: 20 }}
                >
                    {!loading && listar.map((dados, key) => (
                        <a key={dados.id} href={`d/${dados.slug}`}>
                            <div  className="box-design rounded">
                                <div className="info d-flex justify-content-end  align-items-end">
                                    <div>
                                        
                                        {dados.valor == 0 && <span className="valor bg-warning text-dark  border-0"><strong>GRÁTIS</strong></span>}
                                        {dados.formato == 'canva' && <img src="https://design1.com.br/imagens/canva_white.png" className="ps-2" width="50px" />}
                                        {dados.formato == 'psd' && <img src="https://design1.com.br/imagens/photoshop_white.png" className="ps-2" width="27px" />}

                                    </div>
                                </div>
                                <img src={`//design1.com.br/imagens/t/${dados.imagem}`} alt={dados.titulo} className="img-fluid" />
                            </div></a>
                    ))}
                    {loading && <>Carregando...</>}
                </Masonry>


                <hr/>
                <div className="text-center mt-4">
                    <a href="/buscar?q=" className="btn botao-pd pe-5 ps-5"><i className="fa-regular fa-square-plus"></i> VER MAIS</a>
                </div>




            </div></>
)
}
export default ListarDesigns
