import { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";


const Cadastrar = () =>{

    const [nome, setNome] = useState();
    const [email, setEmail] = useState();
    const [senha, setSenha] = useState();
    const [checkemail, setCheckEmail] = useState();
    const [checksenha, setCheckSenha] = useState();
    const [checklength, setCheckLength] = useState(false);
    const [alert, setAlert] = useState(false);


    const [params] = useSearchParams();
    var rt = params.get('rt');




    //VERIFICAR SE O EMAIL EXISTE
    const checkEmail = (e) =>{
        axios.get(`https://api2.design1.com.br/login/checkEmail.php?email=${e.target.value}`).then(function(response) {
            setEmail(e.target.value);
            setCheckEmail(response.data);
    });
    }

    const checkSenha = (e) => {
          if(senha == e.target.value){
            setCheckSenha(false);
          }else{
            setCheckSenha(true);
          }

    }

    const checkTamanho = (e) =>{
        setSenha(e.target.value);
        if(e.target.value.length < 6){
            setCheckLength(true);
          }else{
            setCheckLength(false);

          }
    }

    //ENVIAR
    const Submit = (e) =>{
        e.preventDefault();
        setAlert(true);

        if(!checksenha && !checkemail && !alert){

        let dados = new FormData();
        dados.append("nome", nome);
        dados.append("email", email);
        dados.append("senha", senha);


        axios.post('https://api2.design1.com.br/login/cadastrar.php', dados).then(function(response) {
            if(response.data){

            setTimeout(() => { 
            localStorage.setItem('data',JSON.stringify(response.data));
            if(rt) window.location.replace(`/premium`);  
            else window.location.replace(`/`);
            }, 3000)  


            }
            
    });
}
    }



    return(

       <><>{alert && <div className="alerta bg-success p-3"><i class="fa-solid fa-circle-check"></i> Cadastro realizado com sucesso! Redirecionando...</div>}</>

            <div className="container p-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-4 login p-4 bg-white rounded text-center">
                        <h5 className="mb-3">Cadastrar</h5>

                        <form onSubmit={Submit}>
                            <input type="text" name="nome" className="form-login mb-3" placeholder="Seu nome" required onChange={e => setNome(e.target.value)} />

                            <div className="mb-3">
                                <input type="email" name="email" className="form-login" placeholder="E-mail" required onChange={checkEmail} />
                                {checkemail && <span className="text-danger small">Este email já está em uso</span>}
                            </div>

                            <div className="mb-3">
                                <input type="password" name="senha" className="form-login" placeholder="Senha" required onChange={checkTamanho} />
                                {checklength && <span className="text-danger small">Requer ao menos 6 caracteres</span>}

                            </div>

                            <div className="mb-3">
                                <input type="password" name="rptsenha" className="form-login" placeholder="Repita a senha" required onChange={checkSenha} />
                                {checksenha && <span className="text-danger small">As senhas não conferem</span>}
                            </div>

                            {!checksenha && !checkemail && !alert ? <button type="submit" className="botao-login botao-pd">Cadastrar</button> : <></>}


                        </form>



                        <hr />
                        <a href="login"><strong>Login</strong></a><br />

                    </div>
                </div>
            </div> </>
       
            )

    }
    export default Cadastrar