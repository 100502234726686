import { useState } from "react";

const Buscador = ({titulo}) =>{


    return(
        <div className="buscador pt-3 pb-3 m-auto">
            <div className="form">
            <form action="buscar" className="d-flex align-items-center">
            <input type="text" name="q" className="form-buscador" defaultValue={titulo} placeholder="Buscar um design..."/>
            <button type="submit" className="botao-buscador"><i className="fa-solid fa-magnifying-glass"></i></button>
            </form>
            </div>
        </div>
    )
}
export default Buscador;