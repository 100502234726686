import React from "react";
import { useState } from "react";
import Home from './componentes/home';
import logo from './logo.png';
import './App.css';
import { Link, Route, Routes, BrowserRouter } from "react-router-dom";
import Design from './componentes/design';
import Login from './componentes/login';
import Parceiro from './componentes/parceiro/parceiro';
import Painel from './componentes/painel/painel';
import 'bootstrap/dist/css/bootstrap.min.css';
import FacaLogin from './componentes/parceiro/facalogin';
import Busca from './componentes/busca';
import Cadastrar from './componentes/cadastrar';
import PageParceiro from './componentes/paginaparceiro';
import Termos from './componentes/termosdeuso';
import Politica from './componentes/politica';
import Contato from './componentes/contato';
import RecuperarSenha from './componentes/recuperarsenha';
import Dmca from './componentes/dmca';
import Programa from './componentes/programa-parceria';
import Premium from './componentes/premium';
import User from "./componentes/painel/user";





import ReactGA from "react-ga4";
ReactGA.initialize("G-5393KQWFTE");



function App() {

let user = JSON.parse(localStorage.getItem('data'));


  return (
    
    <>   
    <BrowserRouter>
    
    <div className="container-fluid topo">
      <div className="container pe-4 ps-4 pt-4 pb-4 d-lg-flex justify-content-between align-items-center">

    
        <div><a href="https://design1.com.br"><img src={logo} width="100" /></a></div>

        <div className="d-flex mt-3 mb-3 m-lg-0 align-items-center">

       <Link to="/" className="pe-2"> <i className="fa-solid fa-house"></i></Link>

       {user ? <User user={user} /> : <a href="/login"> <i className="fa-solid fa-circle-user"></i> Entrar</a>  }

       <Link to="/premium" className="botao-premium">  <i className="fa-solid fa-star"></i> Torne-se Premium</Link>
          
       </div>
      </div>
    </div>


    
      <Routes>
              <Route element = {<Home />}  path="/" exact />
              <Route element = {<Design />}  path="/d/:slug" exact />
              {user && <Route element = {<Painel />}  path="/painel" exact />}
              {!user && <Route element = {<Login />}  path="/painel" exact />}
              {user && <Route element = {<Parceiro />}  path="/parceiro" exact />}
              {!user && <Route element = {<FacaLogin />}  path="/parceiro" exact />}
              <Route element = {<Login />}  path="/login" exact />
              <Route element = {<Busca />}  path="/buscar/" exact />
              <Route element = {<Cadastrar />}  path="/cadastrar" exact />
              <Route element = {<PageParceiro />}  path="/:slug" exact />
              <Route element = {<Termos />}  path="/termos-de-uso" exact />
              <Route element = {<Politica />}  path="/politica-de-privacidade" exact />
              <Route element = {<Contato />}  path="/contato" exact />
              <Route element = {<RecuperarSenha />}  path="/recuperar/:chave" exact /> 
              <Route element = {<Dmca />}  path="/dmca" exact />
              <Route element = {<Home />}  path="/canva" exact />
              <Route element = {<Home />}  path="/photoshop" exact />
              <Route element = {<Programa />}  path="/programa-parceria" exact />
              <Route element = {<Premium />}  path="/premium" exact />



          

              
        </Routes>
    
      <div className="container-fluid rodape ">
        <div className="container p-5">
          <Link to="/parceiro">Área do parceiro</Link> | 
          <Link to="/programa-parceria"> Programa de parceria</Link> | 
          <Link to="/termos-de-uso"> Termos de Uso</Link>  | 
          <Link to="/politica-de-privacidade"> Politica de Privacidade</Link> | 
          <Link to="/contato"> Contato</Link> | 
          <Link to="/dmca"> Denuncie</Link> 

         <hr/> <span className="text-secondary small">CNPJ 51.173.610/0001-28</span>

        </div>
      </div>

      </BrowserRouter>
      
      </>

  );
}

export default App;
