import { useEffect, useState } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/modal';
import DadosParceiro from './dadosparceiro';
import ListarDesignsUser from "./listardesigns";
import Pagamentos from "./pagamentos";
import { useSearchParams, Link } from "react-router-dom";




const Painel = () =>{

  let user = JSON.parse(localStorage.getItem('data'));

    const [canva, setCanva] = useState(false);
    const [progress, setProgress] = useState();
    const [inputs, setInputs] = useState([]);
    const [file, setFile] = useState();
    const [show, setShow] = useState(false);
    const [infouser, setInfoUser] = useState([]);
    const [vendas, setVendas] = useState([]);
    const [maxfile, setMaxFile] = useState();
    const [gratis, setGratis] = useState(false);
    const [enviando, setEnviando] = useState(false);
    const [formato, setFormato] = useState([]);


    //GET QUERYS
    const [searchParams] = useSearchParams();
    var view = searchParams.get('view');



    useEffect(() => {
        //INFO USER
        axios.get(`https://api2.design1.com.br/parceiro/infoUser.php?id=${user.id}`)
        .then(function (response) {
          setInfoUser(response.data.usuario);
        });

    //INFO VENDAS
    axios.get(`https://api2.design1.com.br/parceiro/infoVendas.php?autor=${user.id}`).then(function(response) {
        setVendas(response.data);
        
    });

    }, []);



    const linkCanva = () =>{
             setCanva(!canva); 
             setInputs(values => ({...values, ['canvaUrl']: ''}));
    }

    const handleGratis = () =>{
           setGratis(!gratis); 
    }


    //PEGAR VALUES DOS INPUTS
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    //PEGAR UPLOAD E VALIDAR
    const uploadChange = (e) => {

        setFile(e.target.files[0]);
        
        const MAX_FILE_SIZE = 500000 // 500MB
        //FORMATOS ACEITOS
        const fileExtension = e.target.files[0].name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png", "psd"]; 
       

        //CHECAR ARQUIVO 
        if(((e.target.files[0].size/1024) > MAX_FILE_SIZE) || (!allowedFileTypes.includes(fileExtension))) {
            setMaxFile(true)
        }else{
            setMaxFile(false);
            setFormato(fileExtension); //PEGAR FORMATO DO ARQUIVO
        }
    }

   

    //SUBMETER FOIMULÁRIO
    const handleSubmit = async(event) => {
        event.preventDefault();

        setEnviando(true);


       if(!maxfile || canva){
          let dados = new FormData();
          dados.append("file", file);
          dados.append("titulo", inputs.titulo);
          
          //FORMATO
          if(inputs.canvaUrl){
          dados.append("formato", 'canva');
          dados.append("canvaUrl", inputs.canvaUrl);
          }else{ 
            dados.append("formato", formato);
            dados.append("canvaUrl", '');   
          }
          dados.append("gratis", gratis);
          dados.append("autor", user.id);
         


            await axios.post('https://api2.design1.com.br/parceiro/uploadArquivo.php', dados, {
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total)+1)
                  }
            }).then(function(response){
           // console.log(response.data);
           window.location.reload();
        });
    }
}

    return(

        <>
        <DadosParceiro iduser={user.id} vendas={vendas.contar} total={vendas.totalvendas} pix={infouser.pix} tipopix={infouser.tipopix} />

    
        
        <div className="container parceiro p-4">


            <div className="text-center"><button className="btn botao-pd mt-3 mb-5" onClick={() =>  setShow(true)}><i class="fa-solid fa-cloud-arrow-up"></i> Enviar Design</button></div>

            
    
            <div className="row d-flex justify-content-center">
                <div className="col-lg-10" id="designs">

            <ul class="nav nav-tabs d-flex justify-content-center mb-5">
                <li class="nav-item">
                    <Link className={`nav-link ${!view ? `active` : ``} `} aria-current="page" to="/parceiro">Seus designs</Link>
                </li>
                <li class="nav-item">
                    <Link  className={`nav-link ${view ? `active` : ``} `} to="/parceiro?view=pagamentos">Pagamentos</Link>
                </li>
            </ul>

                

               {!view && <ListarDesignsUser user={user.id} nivel={user.nivel}/>}
               {view && <Pagamentos user={user.id}/>}

              

                </div>


                <Modal show={show} onHide={() =>  setShow(false)} centered>
                    <Modal.Header closeButton>
                        <strong>ENVIAR DESIGN</strong>
                    </Modal.Header>
                    <Modal.Body>

                    <div className="alert alert-warning small" role="alert">
                    <i className="fa-solid fa-circle-exclamation"></i> <strong>Importante:</strong> Todo o conteúdo enviado deve ser original, produzido e pensado por você, sem qualquer tipo de plágio. Não utilize fotos ou elementos de terceiros sem autorização. <strong>Caso contrario, sua conta poderá ser banida para sempre. </strong>
                     </div>

                        

                        <form onSubmit={handleSubmit}>
                            <input type="text" className="form-control mb-3" name="titulo" placeholder="Titulo" required onChange={handleChange} />
                           

                            <div className="mb-3 bg-light shadow p-3 rounded">

                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" onClick={linkCanva} role="switch" id="flexSwitchCheckDefault" />
                                <img src="imagens/canva2.png" width="55px" />
                            </div>
                            </div>

                            

                            {canva &&    <div className="mb-3 bg-padrao rounded p-4 text-white"><input type="text" className="form-control" required name="canvaUrl" placeholder="Link canva" onChange={handleChange} /></div>}
                            {!canva && <div className="mb-3 bg-padrao rounded p-4 text-white">
                                <label for="exampleFormControlFile1">Arquivo <span className="small">(PSD, PNG ou JPG)</span></label>
                                <input type="file" required className="form-control" id="exampleFormControlFile1"
                                    name="file" onChange={uploadChange} />
                                    {maxfile && <span className="small text-danger">Arquivo não permitido</span>}
                                    
                               

                                {progress && <div className="progress mt-3">
                                <div className="progress-bar bg-info" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>}

                              
                            </div>}


                            <div className="mb-3 bg-light shadow p-3 rounded">

                             <div className="form-check form-switch">
                                <input className="form-check-input" onChange={handleGratis} type="checkbox"  name="gratis" id="flexSwitchCheckDefault"/>
                                <label className="form-check-label" for="flexSwitchCheckDefault">Conteúdo grátis</label>
                                </div>

                            </div>


                            {!enviando ? <button type="submit" className="btn botao-pd">Enviar</button> :
                            <button className="btn botao-pd" disabled><i className="fa-solid fa-circle-notch fa-spin"></i> Enviando</button> }

                        </form>

                    </Modal.Body>
                </Modal>
            </div>



        </div></>
    )

}
export default Painel;