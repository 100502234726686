import { useEffect, useState } from "react";
import Modal from "react-bootstrap/modal";

import axios from "axios";

const DadosParceiro = ({ iduser, vendas, total, pix, tipopix }) => {

  const [inputs, setInputs] = useState([]); 
  const [showsaque, setShowSaque] = useState(false);
  const [infosaque, setInfoSaque] = useState();



  useEffect(() => {

      //VERIFICAR SAQUES
    axios.get(`https://api2.design1.com.br/parceiro/infoSaques.php?id_user=${iduser}`)
    .then(function (response) {
    if(response.data){
      setInfoSaque(true)
    }else{
      setInfoSaque(false)
    }
  })


  }, []);


  //PEGAR VALUES DOS INPUTS
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };



  const solicitarSaque = (e) =>{
    e.preventDefault();

        let dados = new FormData();
        dados.append("id_user", iduser);
        dados.append("valor", total);

        axios.post(`https://api2.design1.com.br/parceiro/solicitarSaque.php`, dados)
          .then(function (response) {
            alert('Saque solicitado!');
            window.location.replace(`/parceiro?view=pagamentos`);  

          
      })
    
  }


  //FORMATAR MOEDAS

  return (
    <div className="container-fluid p-5 text-white secao1 dadosparceiro">
      <div className="container">
        <div className="d-xl-flex justify-content-between align-items-center">
          <div className="m-3 text-center">
            <strong>{vendas}</strong> designs vendido(s)
            </div>

           <div className="m-3 text-center">
            <a href="javascript:void(0)" onClick={() => setShowSaque(true)} className="link">
              Saldo atual <strong>{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(total)}</strong>
            </a><br/>
            <span className="small2 text-white d-block pt-2">*Saque mínimo R$ 100,00</span>
          </div> 

         

          <Modal show={showsaque} onHide={() => setShowSaque(false)} centered>
            <Modal.Header closeButton>
              <strong>SOLICITAR SAQUE</strong>
            </Modal.Header>
            <Modal.Body>
             

              <div className="mb-3">
              <span className="small text-secondary">Você só pode fazer 2 saques por mês.</span><br/>
              <span className="small text-secondary">O pagamento será feito via PIX em até 2 dias úteis. </span>

              </div>
              <hr/>
              <div className="mb-3">
              <span className="small text-secondary">Seus dados Pix</span><br/>
              <span className="small text-secondary"><strong>{pix} - {tipopix} </strong></span>

              </div>
              
             {total >= 100 && <div className="mb-3">
                  {infosaque ? <div className="alert alert-success" role="alert">
                  Saque solicitado (Verificando pagamento)</div>
                  
                 : <button onClick={solicitarSaque} type="submit" className="btn botao-pd">
                    Solicitar
                  </button>
                  }
              </div>}
            
            </Modal.Body>
          </Modal>



        </div>
      </div>
    </div>
  );
};
export default DadosParceiro;
