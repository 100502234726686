import { useEffect, useState } from "react";
import axios from "axios";



const Pagamentos = ({user}) =>{

    const [pagamentos, setPagamentos] = useState([]);


    useEffect(() => {
        //INFO USER
        axios.get(`https://api2.design1.com.br/parceiro/listarPagamentos.php?user=${user}`)
        .then(function (response) {
          setPagamentos(response.data);
        });
    }, []);

    return(
        <><div className="text-center"> <h5 className="mb-3">Seus pagamentos</h5></div>               

        {pagamentos.map(dados => (
            <div className="list-designs p-2 rounded mb-3 d-flex justify-content-between align-items-center">


            <div className="p-2">
               <strong>{Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(dados.valor)}</strong>  
                <span className="text-secondary small"> - {dados.data}</span>
            </div>

            <div className="p-2">
                {dados.status}
            </div>

            </div>
        ))}
            </>
    )
}
export default Pagamentos;