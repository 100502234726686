import { useEffect, useState } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/modal';
import { useSearchParams } from "react-router-dom";


const ListarDesignsUser = ({user, nivel}) => {


    const [designs, setDesigns] = useState([]);
    const [totalpaginas, setTotalPaginas] = useState();
    const [categorias, setCategorias] = useState([]);
    const [showupdate, setShowUpdate] = useState(false);
    const [update, setUpdate] = useState([]);
    const [destaque, setDestaque] = useState(false);
    const [imagem, setImagem] = useState();





    //GET QUERYS
    const [searchParams] = useSearchParams();
    var getpagina = searchParams.get('pagina');

    if(!getpagina){
       var pagina = 1
    }else{
       var pagina = getpagina
    } 



     //LISTAR DESIGNS DO AUTOR
     useEffect(() => {
        //LISTAR CATEGORIAS
        axios.get(`https://api2.design1.com.br/admin/listarCategorias.php`).then(function(response) {
        setCategorias(response.data);
    })


    if(nivel == 'admin'){

        axios.get(`https://api2.design1.com.br/admin/todosDesigns.php?pagina=${pagina}`).then(function(response) {
        setDesigns(response.data.designs);
        setTotalPaginas(response.data.totalpages);
        
    });

    }else{
    axios.get(`https://api2.design1.com.br/parceiro/seusDesigns.php?autor=${user}&pagina=${pagina}`).then(function(response) {
        setDesigns(response.data.designs);
        setTotalPaginas(response.data.totalpages);

        
    });
 }


    }, [pagina]);


    //ABRIR O MODAL UPDATE
    const abrirUpdate = async(e) => {
        e.preventDefault();
        const id = e.currentTarget.id;
        await axios.get(`https://api2.design1.com.br/parceiro/dadosDesignUpdate.php?id_design=${id}`).then(function(response) {
            setUpdate(response.data);
         });

        setShowUpdate(true) 
    }

    const handleChangeUpdate = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setUpdate(values => ({...values, [name]: value}));
        
    }

      //PEGAR IMAGEM
    const uploadImagemChange = (e) => {
    setImagem(e.target.files[0]);
   } 


   const atualizarDesign = async(e) =>{
    const id = e.currentTarget.id;
    e.preventDefault();
    let dados = new FormData();
    dados.append("id", id);
    dados.append("titulo", update.titulo);
    dados.append("categoria", update.categoria);
    dados.append("status", update.status);


    if(imagem){
        dados.append("imagem", imagem);
    }else{
        dados.append("imagem", update.imagem);
    }

    if(destaque){
        dados.append("destaque", '1');
    }else{
        dados.append("destaque", '0');
    }

    await axios.post('https://api2.design1.com.br/admin/updateDesign.php', dados).then(function(response) {
        window.location.reload();
         });

    
}

const Delete = (e) => {
    const id = e.currentTarget.id;
    const arquivo = e.currentTarget.name;

    axios.delete(`https://api2.design1.com.br/parceiro/excluir.php?id=${id}&arquivo=${arquivo}`).then(function(response) {
       console.log(response.data)
        window.location.reload();
    });
    
  };

  const baixarArquivo = (e) =>{
    const id = e.currentTarget.id;
    axios.get(`https://api2.design1.com.br/painel/baixarArquivo.php?arquivo=${id}`).then(function(response) {
        window.location.replace(response.data);  

})
}


    return(
        <><div className="text-center"> <h5 className="mb-3">Seus designs</h5></div>               

                    {designs.map(dados => (
                        <div className="list-designs p-2 rounded mb-3 d-flex justify-content-between align-items-center">

            
                        <div className="p-2">

                           {dados.imagem && <img src={`https://design1.com.br/imagens/thumbs/${dados.imagem}`} width="40px" className="me-2 rounded"/>}
                           {!dados.imagem && <img src={`imagens/sem-imagem.png`} width="40px" className="me-2 rounded"/>}
                           
                            {dados.titulo}
                          
                            {nivel == 'admin' && <span className="text-secondary small"> - {dados.nome}</span>}
                            {nivel == 'admin' && <span className="text-secondary small"> {dados.destaque == 1 && <i class="fa-solid fa-star text-warning"></i> } </span>}
                            {nivel == 'admin' && <span className="text-secondary small">Categoria:  <strong>{dados.categoria}</strong></span>}

                            
                            
                            
                             </div>

                        <div className="p-2">
                        {dados.status == 'Pendente' && <span className="small badge rounded-pill text-bg-secondary me-2"> {dados.status} </span>}
                        {dados.status == 'Aprovado' && <span className="small badge rounded-pill text-bg-success me-2"> {dados.status} </span>}
                        {dados.status == 'Ajuste necessário' && <span className="small badge rounded-pill text-bg-warning me-2"> {dados.status} </span>}

                            {nivel == 'admin' && <a href="javascript:void(0)" id={dados.id} onClick={abrirUpdate} className="btn small bg-light shadow btn-sm me-2"> <i className="fa-solid fa-pen-to-square"></i> EDITAR</a>}
                            {nivel == 'admin' && dados.canvaUrl && <a href={dados.canvaUrl} target="_blank" className="btn small botao-pd btn-sm"><i className="fa-solid fa-image"></i> CANVA</a>}
                            {nivel == 'admin' && dados.arquivo &&  <a onClick={baixarArquivo} id={dados.arquivo} target="_blank" className="btn small botao-pd btn-sm"><i className="fa-solid fa-image"></i> ARQUIVO</a>}

                            {dados.status == 'Aprovado' && <a href={`https://design1.com.br/d/${dados.slug}`} target="_blank"> <i className="fa-solid fa-link"></i> </a>}

                            
                            </div>

                         </div>))}

                <div className="m-3 text-center">
                {pagina === 1 ? <button className="btn btn-btn btn-outline-secondary" disabled>Anterior</button>
                    : <a href={`?pagina=${pagina-1}`} className="btn botao-pd">Anterior</a>}

                <a className="btn btn-btn btn-outline-secondary me-2 ms-2">{pagina}</a>

                {pagina < totalpaginas ? <a  href={`?pagina=${pagina+++1}`} className="btn botao-pd">Próximo</a> :
                    <a className="btn btn-btn btn-outline-secondary" disabled>Próximo</a>}
                </div>
 

                <Modal show={showupdate} onHide={() => setShowUpdate(false)} centered>
                    <Modal.Header closeButton>
                        <strong>EDITAR DESIGN</strong>
                    </Modal.Header>
                    <Modal.Body>

                        <form onSubmit={atualizarDesign} id={update.id}>
                            <input type="text" className="form-control mb-3" onChange={handleChangeUpdate} placeholder="Titulo" defaultValue={update.titulo} name="titulo" />


                            <select defaultValue={update.status} name="status" className="form-select mb-3" onChange={handleChangeUpdate}>
                                <option selected>Status</option>
                                <option value="Aprovado">Aprovado</option>
                                <option value="Ajuste necessário">Ajuste necessário</option>
                                <option value="Pendente">Pendente</option>
                            </select>

                            <select defaultValue={update.categoria} name="categoria" className="form-select mb-3" onChange={handleChangeUpdate}>
                                <option selected>Categoria</option>
                                  {categorias.map(cat => (
                                <option value={cat.id}>{cat.titulo}</option>
                                  ))}
                            </select>


                            

                            <div className="bg-padrao rounded p-4 mb-3 text-center text-white">

                            {update.imagem && <div className="mb-3"><img src={`https://design1.com.br/imagens/arquivos/${update.imagem}`}  width="100px" /></div>}
                            {!update.imagem && <span className="d-block pb-3">Preview do design</span>}
                            <input type="file" name="imagem" accept="image/jpeg" className="form-control mb-3" onChange={uploadImagemChange} />

                            </div>

                            <div className="mb-3 bg-light shadow p-3 rounded">

                             <div className="form-check form-switch">
                                <input className="form-check-input" onChange={(e) => setDestaque(e.target.checked)} type="checkbox" defaultChecked={update.destaque} id="flexSwitchCheckDefault"/>
                              
                                <label className="form-check-label" for="flexSwitchCheckDefault"><i class="fa-solid fa-star text-warning"></i> Destaque</label>
                             </div>

                            </div>

                            <div className="d-flex justify-content-between">
                                <button className="btn btn-danger" onClick={Delete} name={update.arquivo} id={update.id}>Excluir</button>
                                <button type="submit" className="btn botao-pd">Atualizar</button>
                            </div>

                        </form>



                    </Modal.Body>
                </Modal>


                </>
    )
}
export default ListarDesignsUser;