import { useEffect, useState } from "react";
import { useParams, Link} from 'react-router-dom'
import axios from "axios";
import Masonry from 'react-smart-masonry';
import {Helmet} from "react-helmet";


const PageParceiro = () =>{

    const [listar, setDesigns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [totalpaginas, setTotalPaginas] = useState();
    const [info, infoParceiro] = useState([]);

    const pegaurl = useParams(); // pegar slug

    
 
 
    useEffect(() => {
        setLoading(true);
            axios.get(`https://api2.design1.com.br/infoParceiro.php?slug=${pegaurl.slug}`).then(function(response) {
                //console.log(response.data);
                infoParceiro(response.data);
     
            axios.get(`https://api2.design1.com.br/parceiro/listarDesigns.php?pagina=${pagina}&autor=${response.data.id}`).then(function(response) {
            setLoading(false);
            setDesigns(response.data.designs);
            setTotalPaginas(response.data.totalpages);            
            });

        });
           
        }, [pagina]);

    const element = document.getElementById('designs');
    
        const anterior = () =>{
            setPagina((prevState) => prevState - 1);
            element.scrollIntoView({ behavior: 'smooth' });

        }
    
        const proxima = () =>{
            setPagina((prevState) => prevState + 1);
            element.scrollIntoView({ behavior: 'smooth' });

        }
        
        const canonical = window.location.href;

    return( 
    <>
    
    
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${info.nome} - Design1.com.br`}</title>
                <meta name="description" content="Sou um parceiro oficial Design1. Baixe designs prontos do Photoshop e Canva com facilidade."/>
                <link rel="canonical" href={canonical} />
            </Helmet>

    
    <div className="container-fluid secao1 perfil p-5">
            <div className="d-flex justify-content-center">
                <div className="foto-perfil">
                    {!info.foto && <img src="imagens/no-foto.png" />}
                    {info.foto && <img src={`https://design1.com.br/imagens/parceiros/${info.foto}`} />}

                    </div>
            </div>
            {!info.verificado && <div className="text-center mt-3"><h1 className="h4 text-white">{info.nome}</h1></div>}
            {info.verificado &&  <div className="text-center mt-3"><h1 className="h4 text-white">{info.nome} <img src="https://design1.com.br/imagens/verificado.png" width="20"/></h1></div>}
            {info.instagram && <div className="text-center mt-3"><a href={`https://instagram.com/${info.instagram}`} className="btn bt-instagram"><i className="fa-brands fa-instagram"></i> {info.instagram}</a></div>}

        </div>
       
        <div className="container designs p-4" id="designs">

        <Masonry
      breakpoints={{mobile: 0, tablet: 600, desktop: 1200 }}
      columns={{ mobile: 2, tablet: 3, desktop: 5 }}
      gap={{ mobile: 20, tablet: 20, desktop:  20}}
    >
{!loading && listar.map(dados => (
           <a href={`https://design1.com.br/d/${dados.slug}`}> 
           <div className="box-design rounded">
            <div className="info d-flex justify-content-end  align-items-end">
                <div>
               
                {dados.valor == 0 && <span className="valor bg-warning text-dark  border-0"><strong>GRÁTIS</strong></span>}
                {dados.formato == 'canva' && <img src="https://design1.com.br/imagens/canva_white.png" className="ps-2" width="50px"/>}
                {dados.formato == 'psd' && <img src="https://design1.com.br/imagens/photoshop_white.png" className="ps-2" width="27px"/>}

                </div>
            </div>
            <img src={`//design1.com.br/imagens/t/${dados.imagem}`} alt={dados.titulo} className="img-fluid" />
           </div></a>
        ))}
        {loading && <>Carregando...</>}
    </Masonry>

<div className="m-3 text-center">
{pagina === 1 ? <button className="btn btn-btn btn-outline-secondary" disabled>Anterior</button> 
: <button onClick={anterior} className="btn botao-pd">Anterior</button> } 

<button className="btn btn-btn btn-outline-secondary me-2 ms-2">{pagina}</button>  

{pagina < totalpaginas ?  <a onClick={proxima} className="btn botao-pd">Próximo</a> :
<button className="btn btn-btn btn-outline-secondary" disabled>Próximo</button> } 
</div>


</div>
       
       </>

    )
}
export default PageParceiro