import { useEffect, useState } from "react";
import axios from "axios";
import Masonry from 'react-smart-masonry';




const Relacionados = ({titulo, categoria, id}) =>{




    const [listar, setDesigns] = useState([]);
    const [loading, setLoading] = useState(false);
    
    


    //LISTAR DESIGNS RECENTES


    const listaDesigns = () => {
  
         axios.get(`https://api2.design1.com.br/listarRelacionados.php?titulo=${titulo}&categoria=${categoria}&id=${id}`).then(function(response) {
        //console.log(response.data);
        setLoading(false);
        setDesigns(response.data.designs);
        
    });
}


useEffect(() => {
        setLoading(true);
        listaDesigns();
    }, []);


    return(

        <>
       
                <Masonry
                    breakpoints={{ mobile: 0, tablet: 600, desktop: 1200 }}
                    columns={{ mobile: 2, tablet: 3, desktop: 5 }}
                    gap={{ mobile: 20, tablet: 20, desktop: 20 }}
                >
                    {!loading && listar.map((dados, key) => (
                        <a key={dados.id} href={`/d/${dados.slug}`}>
                            <div  className="box-design rounded">
                                <div className="info d-flex justify-content-end  align-items-end">
                                    <div>
                                        
                                        {dados.valor == 0 && <span className="valor bg-warning text-dark  border-0"><strong>GRÁTIS</strong></span>}
                                        {dados.formato == 'canva' && <img src="https://design1.com.br/imagens/canva_white.png" className="ps-2" width="50px" />}
                                        {dados.formato == 'psd' && <img src="https://design1.com.br/imagens/photoshop_white.png" className="ps-2" width="27px" />}

                                    </div>
                                </div>
                                <img src={`//design1.com.br/imagens/t/${dados.imagem}`} alt={dados.titulo} className="img-fluid" />
                            </div></a>
                    ))}
                    {loading && <>Carregando...</>}
                </Masonry>



</>
)
}
export default Relacionados
