import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/modal";
import axios from "axios";



const User = ({user}) => {

  const [showdados, setShowDados] = useState(false);
  const [infouser, setInfoUser] = useState([]);
  const [previewfile, setpreviewFile] = useState();
  const [inputs, setInputs] = useState([]);
  const [username, setUsername] = useState();
  const [file, setFile] = useState();
  const [erroextensao, setErroExtensao] = useState();
  const [checkusername, setCUsername] = useState();
  const [caracter, setCaracter] = useState(false);
  const [showsenha, setShowSenha] = useState(false);
  const [errosenha, setErroSenha] = useState(false);
  const [designs, setDesigns] = useState([]);



  useEffect(() => {
    axios
      .get(`https://api2.design1.com.br/parceiro/infoUser.php?id=${user.id}`)
      .then(function (response) {
        setInfoUser(response.data.usuario);
        setDesigns(response.data.designs);

      });

  }, []);


  const atualizarDados = (e) => {
    e.preventDefault();
    let dados = new FormData();

    dados.append("id", user.id);
    {
      inputs.nome
        ? dados.append("nome", inputs.nome)
        : dados.append("nome", infouser.nome);
    }
    {
      username
        ? dados.append("slug", username)
        : dados.append("slug", infouser.slug);
    }
    {
      inputs.tipopix
        ? dados.append("tipopix", inputs.tipopix)
        : dados.append("tipopix", infouser.tipopix);
    }
    {
      inputs.pix
        ? dados.append("pix", inputs.pix)
        : dados.append("pix", infouser.pix);
    }
    {
      inputs.cpf
        ? dados.append("cpf", inputs.cpf)
        : dados.append("cpf", infouser.cpf);
    }
    {
      inputs.instagram
        ? dados.append("instagram", inputs.instagram)
        : dados.append("instagram", infouser.instagram);
    }
    {
      file
        ? dados.append("imagem", file)
        : dados.append("imagem", infouser.foto);
    }

    axios
      .post(`https://api2.design1.com.br/parceiro/updateUser.php`, dados)
      .then(function (response) {
        window.location.reload();
      });
  };

  //PEGAR UPLOAD E VALIDAR
  const uploadChange = (e) => {
   
    //CHECAR EXTENSAO DO ARQUIVO
    const fileExtension = e.target.files[0].name.split(".").at(-1);
    const allowedFileTypes = ["jpg"];
    if (!allowedFileTypes.includes(fileExtension)) {
        setErroExtensao(true);
    }else{
        setErroExtensao(false)
        setpreviewFile(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

};

 //PEGAR VALUES DOS INPUTS
 const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  //verificar se já existe username
  const checarUsername = (e) => {
    e.preventDefault();
    axios
      .get(
        `https://api2.design1.com.br/parceiro/checkUsername.php?slug=${e.target.value}&slugatual=${infouser.slug}`
      )
      .then(function (response) {
        setCUsername(response.data);
        setUsername(e.target.value);

        //VERIFICAR CARACTERES
        if (e.target.value.match(/[^a-z-]/gi)) {
          setCaracter(true);
        } else {
          setCaracter(false);
        }
      });
  };


  const atualizarSenha = (e) => {
    e.preventDefault();

    let dados = new FormData();
    dados.append("id", user.id);
    dados.append("senha", inputs.senhaatual);
    dados.append("novasenha", inputs.novasenha);
    dados.append("rptnovasenha", inputs.rptnovasenha);

    axios
      .post(`https://api2.design1.com.br/parceiro/updateSenha.php`, dados)
      .then(function (response) {
        if(response.data){
            setErroSenha(false);
            alert('Senha alterada com sucesso')
            window.location.reload()
        }else{
            setErroSenha(true);
        }
      });

  }


  const logout = () => {
    localStorage.setItem("data", "");
    localStorage.clear();
    window.location.replace(`/`);
  };


    return(
        <>
        <Dropdown className="link-entrar">

        <Dropdown.Toggle
        
          className="dropdown-toggle link-entrar"
          variant=""
          id="dropdown-basic"
        >
          Olá, {user.nome}
        </Dropdown.Toggle>

        <Dropdown.Menu>
         {user.slug && <Dropdown.Item href={`https://design1.com.br/${user.slug}`}> Meu perfil </Dropdown.Item> }
         <Dropdown.Item href="/painel" >Painel</Dropdown.Item>
          {designs >= 1 && <Dropdown.Item  onClick={() => setShowDados(true)}>Meus dados</Dropdown.Item>}
          <Dropdown.Item  onClick={() => setShowSenha(true)}>Alterar senha</Dropdown.Item>
          {designs < 1 && <Dropdown.Item href="/parceiro">Seja parceiro</Dropdown.Item>}
          {designs >= 1 && <Dropdown.Item href="/parceiro">Parceiro</Dropdown.Item>}
          <Dropdown.Item onClick={logout}>Sair </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> 




      <Modal show={showdados} onHide={() => setShowDados(false)} centered>
            <Modal.Header closeButton>
              <strong>MEUS DADOS</strong>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={atualizarDados}>
                <div className="dadosparceiro mb-3 d-flex justify-content-center position-relative">
                  {file && (
                    <div className="foto-autor">
                      <img src={previewfile} />
                    </div>
                  )}
                  {!infouser.foto && !file && (
                    <div className="foto-autor">
                      <img src={`https://design1.com.br/imagens/no-foto.png`} />
                    </div>
                  )}
                  {infouser.foto && !file && (
                    <div className="foto-autor">
                      <img
                        src={`https://design1.com.br/imagens/parceiros/${infouser.foto}`}
                      />
                    </div>
                  )}

                  <input
                    accept="image/jpeg"
                    type="file"
                    name="imagem"
                    id="avatar"
                    onChange={uploadChange}
                  />

                </div>
                {erroextensao && <span className="small text-danger">Apenas o formato JPEG é permitido!</span>}

                <span className="small text-secondary">Seu nome:</span>
                <input
                  type="text"
                  className="form-control mb-3"
                  defaultValue={infouser.nome}
                  onChange={handleChange}
                  name="nome"
                />

                <span className="small text-secondary">Username:</span>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="slug"
                    defaultValue={infouser.slug}
                    onChange={checarUsername}
                  />
                  {checkusername ? (
                    <span className="text-danger small">
                      Este username já está em uso <br />
                    </span>
                  ) : (
                    <></>
                  )}
                  {caracter ? (
                    <span className="text-danger small">
                      Utilize apenas letras{" "}
                    </span>
                  ) : (
                    <></>
                  )}

                  <span className="small text-secondary">
                    Ex: design1.com.br/username
                  </span>
                </div>
                <span className="small  text-secondary">Instagram:</span>
                <input
                  type="text"
                  className="form-control mb-3"
                  defaultValue={infouser.instagram}
                  onChange={handleChange}
                  name="instagram"
                />

                <div className="bg-padrao text-white p-3 rounded mb-3">
                  <strong>Chave Pix</strong>

                  <select
                    defaultValue={infouser.tipopix}
                    name="tipopix"
                    className="form-select mb-3"
                    onChange={handleChange}
                  >
                    <option selected>Tipo Pix</option>
                    <option value="CPF">CPF</option>
                    <option value="Celular">Celular</option>
                    <option value="E-mail">E-mail</option>

                  </select>

                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Número PIX"
                    defaultValue={infouser.pix}
                    name="pix"
                    onChange={handleChange}
                  />
                </div>

                <span className="small text-secondary">CPF</span>
                <input
                  type="text"
                  className="form-control mb-3"
                  defaultValue={infouser.cpf}
                  name="cpf"
                  onChange={handleChange}
                />

                {checkusername || caracter ? (
                  <button type="submit" className="btn btn-secondary" disabled>
                    Salvar
                  </button>
                ) : (
                  <button type="submit" className="btn botao-pd">
                    Salvar
                  </button>
                )}
              </form>
            </Modal.Body>
          </Modal>




          <Modal show={showsenha} onHide={() => setShowSenha(false)} centered>
            <Modal.Header closeButton>
              <strong>ALTERAR SENHA</strong>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={atualizarSenha}>
                
              <span className="small text-secondary">Senha atual:</span>
                <input
                  type="password"
                  className="form-control mb-3"
                  onChange={handleChange}
                  name="senhaatual"
                  autoComplete="new-password"
                  required

                />

              <span className="small text-secondary">Nova senha:</span>

                <input
                  type="password"
                  className="form-control mb-3"
                  onChange={handleChange}
                  name="novasenha"
                  autoComplete="new-password"
                  required
                  minlength="6"
                />


                <div className="mb-3">
               <span className="small text-secondary">Repetir a nova senha:</span>
                <input
                  type="password"
                  className="form-control"
                  onChange={handleChange}
                  name="rptnovasenha"
                  autoComplete="new-password"
                  required
                 
                />

                {errosenha && <span className="text-danger small">
                   Dados incorretos <br />
                </span>}


                    </div>

                  <button type="submit" className="btn botao-pd">
                    Salvar
                  </button>
                
              </form>
            </Modal.Body>
          </Modal>


      </>
    )
}
export default User;