import axios from "axios";
import { useEffect, useState } from "react";

const Painel = () =>{

    const [compras, setCompras] = useState([]);
    const [quantidade, setQuantidade] = useState([]);


    let user = JSON.parse(localStorage.getItem('data'));
    
    //LISTAR COMPRAS
    useEffect(() => {
            axios.get(`https://api2.design1.com.br/painel/suasCompras.php?user=${user.id}`).then(function(response) {
            setCompras(response.data.downloads);
            setQuantidade(response.data.quantidade);

            
        });
    
        }, []);

        const baixarArquivo = (e) =>{
            const id = e.currentTarget.id;
            axios.get(`https://api2.design1.com.br/painel/baixarArquivo.php?arquivo=${id}`).then(function(response) {
                window.location.replace(response.data);  

        })


        }
    

    return(
        <div className="container p-4">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-9" id="designs">

                <div className="text-center"> <h5 className="mb-3">Suas compras</h5></div> 

                <div className="bg-padrao text-white rounded text-center p-4 mb-3">Downloads disponíveis: <strong>{quantidade}</strong>
                
                
                </div>


                {compras.map(dados => (
                        <div className="list-designs p-2 rounded mb-3 d-flex justify-content-between align-items-center">
                        <div className="p-2">

                            {dados.imagem && <img src={`https://design1.com.br/imagens/thumbs/${dados.imagem}`} width="40px" className="me-2 rounded"/>}
                            {!dados.imagem && <img src={`imagens/sem-imagem.png`} width="40px" className="me-2 rounded"/>}

                            {dados.titulo}
                            </div>

                            <div className="p-2">
                            {dados.canvaUrl && <a href={dados.canvaUrl} target="_blank" className="btn small botao-pd btn-sm"><i className="fa-solid fa-image"></i> LINK CANVA</a>}
                            {dados.arquivo &&  <a onClick={baixarArquivo} id={dados.arquivo} target="_blank" className="btn small botao-pd btn-sm"><i className="fa-solid fa-image"></i> BAIXAR PSD</a>}
                            
                            </div>
                        </div>
                ))}

                </div>
            </div>
        </div>
    )

}
export default Painel;