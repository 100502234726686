import Buscador from '../componentes/buscador';
import ListarDesigns from "./listardesigns";
import { useEffect, useState } from "react";
import axios from "axios";




const Home = () => {

   //CATEGORIAS EM ALTA

   const [listarcat, setCat] = useState([]);

   useEffect(() => {
      axios.get(`https://api2.design1.com.br/categoriasAlta.php`).then(function(response) {
        setCat(response.data);
   })
  }, []);


    return( 

    <>
    
<div className="container-fluid secao1">
    <div className="container p-4">

<div className="d-flex justify-content-center">
  <div className="frase-titulo">
    <h1 className="text-center"> Aumente sua produtividade! <strong>Baixe designs prontos com facilidade</strong></h1>
    <h6 className="text-center text-white">Sem mensalidade. Arquivos editáveis em PSD e Canva a partir de R$ 0,90.</h6>
  </div>
</div>


<Buscador/>

<div className="m-auto text-center logodesign">
    <img src="imagens/canva_white.png" className="m-2" style={{height: '20px'}}/> 
    <img src="imagens/photoshop_white.png" className="m-2" style={{height: '30px'}}/>

    </div>

</div>
</div>

<div className="container p-4 mt-3">

<div className="row">

  <h6 className="text-center mb-4">🔥 Categorias em alta</h6>
      
    {listarcat.map(dados => ( 
    <div className="col-lg-3 col-6 mb-3">
        <a href={dados.url} className="box-cat" style={{backgroundImage: `linear-gradient(to bottom, rgba(112,45,250,0.3) 0%, rgba(0,0,0,0.7) 100%), url(${dados.imagemUrl})`}}> {dados.titulo}</a>
     </div>))}
     


 </div>

 </div>
    
    <ListarDesigns/>


    

       
        </>  
)

} 
export default Home;