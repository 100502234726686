import { useEffect, useState } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/modal';
import { useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";




const Login = () =>{

    const [email, setEmail] = useState();
    const [senha, setSenha] = useState();
    const [erro, setErro] = useState(false);
    const [emailrec, setEmailrec] = useState();
    const [modalrec, setModal] = useState(false);
    const [errorec, setErrorec] = useState(true);
    const [successrec, setSuccessRec] = useState(false);
    const [captcha, setCaptcha] = useState(false);
    const [alert, setAlert] = useState(false);


    const [params] = useSearchParams();
    var rt = params.get('rt');


        function onChange(value) {
        console.log("Captcha value:", value);
        }



    
    const Entrar = (e) =>{
        e.preventDefault()

        let dados = new FormData();
        dados.append("email", email);
        dados.append("senha", senha);

         axios.post('https://api2.design1.com.br/login/login.php', dados).then(function(response) {
            if(response.data){

                setAlert(true)

                setTimeout(() => { 
                localStorage.setItem('data',JSON.stringify(response.data));
                if(rt) window.location.replace(`/premium`);  
                else window.location.replace(`/`); 

               }, 3000)
                
                
            }else{
                setErro(true);
            }
             });
    }

    const recuperarSenha = (e) =>{
        e.preventDefault();

        axios.post('https://api2.design1.com.br/login/enviarSenha.php', {emailrec}).then(function(response) {
        
        if(response.data){
            setErrorec(true)
            setSuccessRec(true)
        }else{
            setErrorec(false)
        }
            
    })

    }


    return(
  
       <><>{alert && <div className="alerta bg-success p-3"><i class="fa-solid fa-circle-check"></i> Login realizado com sucesso! Redirecionando...</div>}</>
       
       <div className="container p-5">

            <div className="row d-flex justify-content-center">
                <div className="col-lg-4 login p-4 bg-white rounded text-center">
                    <h5 className="mb-3">Login</h5>

                    <form onSubmit={Entrar}>
                        <input type="email" name="email" className="form-login mb-3" placeholder="E-mail" onChange={e => setEmail(e.target.value)} />
                        <input type="password" name="senha" className="form-login mb-3" placeholder="Senha" onChange={e => setSenha(e.target.value)} />

                        <div className="mb-3 d-flex justify-content-center">
                            <ReCAPTCHA
                                sitekey="6LdlZh4mAAAAANScyadZL6GYk-AqyUx12VPjeKUA"
                                onChange={setCaptcha} />
                        </div>

                        {captcha && <button type="submit" className="botao-login botao-pd">Entrar</button>}

                    </form>
                    {erro && <div className=" mt-3 alert alert-danger" role="alert">Dados de login inválidos</div>}
                    <hr />
                    {rt ? <a href="/cadastrar?rt=premium"><strong>Cadastrar</strong></a> :
                        <a href="cadastrar"><strong>Cadastrar</strong></a>} <br />
                    <a href="javascript:void(0)" onClick={() => setModal(true)}>Esqueci a senha</a>

                </div>
            </div>


            <Modal show={modalrec} onHide={() => setModal(false)} centered>
                <Modal.Header closeButton>
                    <strong>RECUPERAR SENHA</strong>
                </Modal.Header>
                <Modal.Body>

                    <form onSubmit={recuperarSenha}>
                        <span className="text-secondary small">Digite o e-mail associado a sua conta e enviaremos um link para redefinir sua senha.</span>
                        <div className="mt-3 mb-3">
                            <input type="email" className="form-control" onChange={(event) => setEmailrec(event.target.value)} placeholder="Informe seu e-mail" name="emailrec" />
                            {!errorec && <span className="text-danger small">Este e-mail não está associado a uma conta Desgin1.</span>}
                            {successrec && <div className="mt-3 alert alert-success" role="alert">Um e-mail foi enviado com instruções para recuperar sua senha.</div>}
                            
                          
                            
                           
                        </div>

                        <button type="submit" className="btn botao-pd">Recuperar</button>
                    </form>

                </Modal.Body>
            </Modal>

        </div></>
    )

    
}
export default Login;