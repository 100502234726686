const Termos = () =>{
    return(
        <div className="container p-5">
            <h1 className="h5">Termos de uso</h1>

            <p>O Design1 é um site que permite aos designers baixar arquivos de design para uso em seus projetos. O uso do site está sujeito aos seguintes termos e condições:</p>

            <p>
            <strong>1 - Aceitação dos Termos de Serviço: </strong><br/>
            Ao usar o Design1, você concorda com estes Termos de Serviço. Se você não concorda com estes Termos de Serviço, não use o Design1.
            </p>

            <p><strong>2 - Propriedade Intelectual:</strong><br/>
            Todo o conteúdo do Design1, incluindo os arquivos de design disponíveis para download, são protegidos por direitos autorais, marca registrada, segredo comercial e outras leis e tratados de propriedade intelectual. Você não pode usar o conteúdo do Design1 de qualquer forma que viole essas leis e tratados.</p>

            <p><strong>3 - Direitos de Uso:</strong> <br/>
               O Design1 concede a você uma licença limitada, não exclusiva e intransferível para baixar e usar o conteúdo do Design1 para seus próprios projetos de design. Você não pode distribuir, revender ou usar o conteúdo do Design1 de qualquer outra forma.</p>
            
            <p><strong>4 - Responsabilidade pelo Conteúdo:</strong><br/>
                O Design1 não é responsável pelo conteúdo dos arquivos de design disponíveis para download. Você é o único responsável por garantir que os arquivos de design atendam às suas necessidades e requisitos específicos.</p>
            
            <p>
            <strong> 5 - Notificação de Violação de Direitos Autorais:</strong><br/>
Caso você identifique que qualquer imagem ou outro material disponibilizado pelo Design1 viola quaisquer direitos autorais que você possua ou controle, é possível comunicar essa violação ao Design1 por meio dos procedimentos estabelecidos em nossa Notificação de Violação de Direitos Autorais DMCA.
          </p>

          <p> <strong>6 - Conteúdo dos parceiros:</strong><br/>
          Design1 se empenha em assegurar a legalidade e a qualidade do conteúdo fornecido pelos parceiros. No entanto, Design1 não pode monitorar ou controlar todo o conteúdo fornecido pelos parceiros. Portanto, o usuário reconhece e concorda que Design1 não será responsável por avaliar a originalidade, não violação de direitos de terceiros ou legalidade do conteúdo dos parceiros e que Design1 não garante nem é responsável por tais conteúdos, exceto nos casos expressamente previstos na regulamentação aplicável.</p>

            <p><strong>7 - Limitação de Responsabilidade: </strong><br/>
                Em nenhum caso o Design1 será responsável por quaisquer danos diretos, indiretos, incidentais, especiais, consequenciais ou punitivos decorrentes do uso ou incapacidade de usar o site ou do conteúdo do site.</p>

<p>Ao usar o Design1, você concorda em cumprir estes Termos de Serviço e todas as leis e regulamentos aplicáveis.




</p>
        </div>
    )

}
export default Termos