import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom'
import Modal from 'react-bootstrap/modal';
import {Helmet} from "react-helmet";
import Relacionados from "./relacionados"



const Design = () => {
   
    const [info, infoDesign] = useState([]);
    const [download, setDownload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState();



    let user = JSON.parse(localStorage.getItem('data'));

    const pegaurl = useParams(); // pegar slug

    useEffect(() => {
        setLoading(true);
            axios.get(`https://api2.design1.com.br/infoDesign.php?slug=${pegaurl.slug}`).then(function(response) {
                //console.log(response.data);
                infoDesign(response.data);
                setLoading(false);
            });

            //CHECAR SE EXISTE DOWNLOAD DISPONÍVEL
            if(user){
            axios.get(`https://api2.design1.com.br/checarDownload.php?user=${user.id}`).then(function(response) {
                //console.log(response.data);
                setDownload(response.data);

            });
           }

        }, []);
      


   //BAIXAR ARQUIVO
   const baixarArquivo = async(e) => {
    e.preventDefault();
    await axios.get(`https://api2.design1.com.br/baixarArquivo.php?user=${user.id}&design=${info.id_design}&autor=${info.autor}`).then(function(response) {
    setShow(true)
    setUrl(response.data.url);
        
   });
          
   }

   const baixarGratis = (e) =>{

    e.preventDefault();
    axios.get(`https://api2.design1.com.br/baixarArquivo.php?user=${user.id}&design=${info.id_design}&autor=${info.autor}&valor=gratis`).then(function(response) {
    setShow(true)
    setUrl(response.data.url);
        
   });

   }


    const canonical = window.location.href;

    if(loading){ 
    return(
    <div className="container p-5"><i className="fa-solid fa-circle-notch fa-spin"></i> Carregando...</div>)
    }else{
       
    return(

        <>
        

           <Helmet>
                <meta charSet="utf-8" />
                <title>{`${info.titulo} - Design1.com.br`}</title>
                 <meta name="description" content="Baixe designs prontos do Canva e Photoshop com facilidade."/>
                 <link rel="canonical" href={canonical} />


                <meta property="og:type" content="website"/>
                <meta property="og:url" content={canonical}/>
                <meta property="og:title" content={`${info.titulo} - Design1.com.br`}/>
                <meta property="og:description" content="Baixe designs prontos do Canva e Photoshop com facilidade."/>
                <meta property="og:image" content={`https://design1.com.br/imagens/v/${info.imagem}`}/>

     
                <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "ImageObject",
                        "contentUrl": "https://design1.com.br/imagens/v/${info.imagem}",
                        "license": "https://www.design1.com.br",
                        "acquireLicensePage": "https://www.design1.com.br/premium"
                    }
                `}</script>


            </Helmet>
        
        
        
        <div className="container p-4 mt-4">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-4 mb-3">
                    <img src={`https://design1.com.br/imagens/v/${info.imagem}`} alt={info.titulo} className="img-fluid rounded" />
                </div>
                <div className="col-lg-6">

                    <h1 className="h5 mb-4"><strong>{info.titulo}</strong></h1>

                  
                    {info.valor == 0 && <span className="badge rounded-pill text-bg-warning mb-4"><strong>GRÁTIS</strong></span>}
                


                    <div className="design-info mb-3">
                        <i className="fa-solid fa-check"></i> Não precisa atribuir o autor<br />
                        <i className="fa-solid fa-check"></i> Para projetos pessoais e comerciais<br />
                        <i className="fa-solid fa-check"></i> Liberação imediata
                    </div>

                    {info.formato == 'psd' && <div className="info-plataforma mb-3"><img src="../imagens/photoshop.png" width="35px" /> Arquivo editável no Photoshop</div>}
                    {info.formato == 'canva' && <div className="info-plataforma mb-3"><img src="../imagens/canva.png" width="35px" /> Arquivo editável no Canva</div>}
                    {info.formato == 'jpg' && <div className="info-plataforma mb-3"><img src="../imagens/jpeg.png" width="35px" /> Imagem JPG</div>}
                    {info.formato == 'png' && <div className="info-plataforma mb-3"><img src="../imagens/png.png" width="35px" /> Elemento PNG</div>}
                    

                
                   {info.valor == 0 &&

                    <div className="d-grid gap-2 text-center">
                    <a onClick={baixarGratis} className="btn botao-pd p-3"><i className="fa-solid fa-arrow-down"></i> <strong>BAIXAR ARQUIVO GRÁTIS</strong></a>
                    <span className="text-secondary small text-center d-block">Download gratuito</span>
                    </div>
                   
                   }


                
                   {!info.valor == 0 && <div>

                   {!download || !user ? <div className="d-grid gap-2 text-center">

                    <div className="aviso-premium rounded">
                    Arquivo disponível para usuários premium
                    </div>
                    <a href="../premium"className="btn bt-premium p-3"> <i class="fa-solid fa-star"></i> SEJA PREMIUM</a>
                    </div>:

                     <div className="d-grid gap-2 text-center">
                     <a onClick={baixarArquivo} className="btn botao-pd p-3"><i className="fa-solid fa-arrow-down"></i> <strong>BAIXAR ARQUIVO</strong></a>
                     <span className="text-secondary small text-center d-block">Download liberado</span>
                    </div>
                    }

                    </div>}

                    <div className="mt-3 d-flex align-items-center autor">
                        <div className="foto-autor me-1">
                        {!info.foto && <img src="https://design1.com.br/imagens/no-foto.png" />}
                        {info.foto && <img src={`https://design1.com.br/imagens/parceiros/${info.foto}`} />}
                            </div>
                            {!info.verificado && <a href={`../${info.linkuser}`}><strong> {info.nome}</strong></a>}
                            {info.verificado && <a href={`../${info.linkuser}`}><strong> {info.nome}</strong> <img src="https://design1.com.br/imagens/verificado.png" width="20"/></a>}
                    
                    </div>

                </div>

            </div>
        </div>


        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Body className="text-center p-5">
            <span className="small d-block mb-1"><strong><i class="fa-solid fa-circle-check"></i> Arquivo liberado!</strong> Clique no botão abaixo para acessar.</span><br/>
             <a href={url} className="btn botao-pd" target="_blank">BAIXAR ARQUIVO</a>


            </Modal.Body>

            </Modal>


            <div className="container p-4">
                <h5>Relacionados</h5>
                <hr/>
                <Relacionados titulo = {info.titulo} id ={info.id_design} categoria = {info.categoria} />
            </div>


        
        </>

                    
    )}

}
export default Design;